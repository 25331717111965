@import 'antd-theme.less';

/* insert custom global style */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html,
body {
  line-height: 1;
  font-family: 'Noto Sans KR', 'Noto Sans TC', Arial, Helvetica, sans-serif;
  letter-spacing: -0.32px;
  color: #0f0f0f;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
img {
  object-fit: contain;
}
button {
  all: unset;
  text-align: center;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2 {
  font-family: 'Noto Sans KR', 'Noto Sans TC', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
p {
  font-size: 1em;
  font-family: 'Noto Sans KR', 'Noto Sans TC', Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.32px;
  white-space: pre-line;
  word-wrap: break-word;
}
#root {
  position: relative;
  white-space: pre-line;
  word-break: keep-all;
  word-wrap: break-word;
}

.pd-flex {
  display: flex;
  flex-direction: column;
}

.pd-flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pd-flex-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pd-align-center {
  align-items: center;
}
.pd-align-top {
  align-items: top;
}

.pd-justify-center {
  justify-content: center;
}

.pd-flex-row {
  display: flex;
  flex-direction: row;
}

.pd-box-wrapper {
  width: 100%;
  position: relative;
  z-index: 3;
}

// modal style
.ant-modal-content {
  padding-top: 82px;
  padding-bottom: 59px;
  border-radius: 24.5px;
}
.ant-modal-body {
  padding: 0;
}
.ant-modal-close {
  display: none;
}
.ant-modal-footer {
  padding: 0;
  border-top: 0;
  text-align: center;
}

@primary-color: #f7322f;@link-color: #0f0f0f;